<template>
    <el-card class="detailBox" v-loading="dataLoading">
        <el-table
            :data="card_list"
            v-if="tableLoaded"
            ref="multipleTable"
            :row-key="getRowKeys"
            height="250px"
            style="width: 100%; margin: 0 10px;flex:1;font-size: 14px;"
            class="outstanding_dialog_table_class"
            @row-click="rowClicked"
            @selection-change="handleSelectionChange"
            :cell-style="changeCellStyle"
        >
            <el-table-column
                :reserve-selection="true"
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column :label="$t('home.projectName')" prop="projectName" width="250">
                <template slot-scope="scope">
                    <span class="colColor" @click="goProject(scope.row.projectId)">{{ scope.row.projectName }}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('home.numberOfPeople')" prop="num" width="250"></el-table-column>
            <el-table-column min-width="520" prop="fileCount">
                <template slot="header">
                    <span class="sortable-column-header" @click="handleSort('fileNums')"
                        >{{ $t('home.numberOfFiles') }}
                        <div class="caret-wrapper">
                        <i
                            :class="{
                            'el-icon-caret-top': true,
                            'el-icon-caret-colored':
                            sortType == 'fileNums' && descObj['fileNums'] == false,
                            }"
                        ></i>
                        <i
                            :class="{
                            'el-icon-caret-bottom': true,
                            'el-icon-caret-colored':
                            sortType == 'fileNums' && descObj['fileNums'] == true,
                            }"
                        ></i>
                        </div>
                    </span>
                </template>
                <template slot-scope="scope">
                    <el-tooltip 
                        :disabled="!(scope.row.fileCount> 0)" 
                        placement="top" 
                        effect="dark">
                        <div slot="content">
                            <span class="completedDot"></span>{{$t('home.status.completed')+':'+scope.row.fileNum[0]}}<br/>
                            <span class="inProgressDot"></span>{{$t('home.status.inProgress')+':'+scope.row.fileNum[1]}}<br/>
                            <span class="notStartedDot"></span>{{$t('home.status.notStarted')+':'+scope.row.fileNum[2] }}<br/>
                            <span class="totalDot"></span>{{$t('home.total')+':'+scope.row.fileCount }}
                        </div>
                        <div
                            style="
                                height: 20px;
                                border-radius: 4px;
                                background-color: #a6a6a6;
                                display: flex;
                                align-items: center;
                            "
                            :style="'max-width: 480px;width:'+
                            (scope.row.fileCount/maxFileNum * 480)+ 'px'
                            "
                            >
                            <div
                                :style="
                                'background-color:#2a82e4;border-radius: 4px;display: flex;align-items: center;width:'+
                                (((scope.row.fileNum[0]+scope.row.fileNum[1])/scope.row.fileCount) * (scope.row.fileCount/maxFileNum * 480))+
                                    'px'
                            ">
                                    <div
                                        v-if="scope.row.fileNum[0] > 0"
                                        :style="
                                        'color:rgb(0 167 11);height:20px;border-radius: 4px;display: flex;justify-content: center;align-items: center;background-color:#43cf7c;' +
                                        'width:' +
                                        ((scope.row.fileNum[0]/scope.row.fileCount) * (scope.row.fileCount/maxFileNum * 480)) +
                                        'px'
                                        "
                                    >
                                    </div>
                                    <div
                                        v-if="scope.row.fileNum[1] > 0"
                                        :style="
                                        'color:#2a82e4;height:20px;border-radius: 4px;display: flex;justify-content: center;align-items: center;background-color:#2a82e4;' +
                                        'width:' +
                                        ((scope.row.fileNum[1]/scope.row.fileCount) * (scope.row.fileCount/maxFileNum * 480)) +
                                        'px'
                                        "
                                    >
                                    </div>
                                
                            </div>
                                <div
                                    v-if="scope.row.fileNum[2] > 0"
                                    :style="
                                    'height:20px;display: flex;justify-content: center;align-items: center;' +
                                    'width:' +
                                    ((scope.row.fileNum[2]/scope.row.fileCount) * (scope.row.fileCount/maxFileNum * 480))+
                                    'px'
                                    "
                                >
                                </div>
                        </div>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column min-width="520" prop="nodeCount">
                <template slot="header">
                    <span class="sortable-column-header" @click="handleSort('nodeNums')"
                        >{{ $t('home.numberOfNodes') }}
                        <div class="caret-wrapper">
                        <i
                            :class="{
                            'el-icon-caret-top': true,
                            'el-icon-caret-colored':
                            sortType == 'nodeNums' && descObj['nodeNums'] == false,
                            }"
                        ></i>
                        <i
                            :class="{
                            'el-icon-caret-bottom': true,
                            'el-icon-caret-colored':
                            sortType == 'nodeNums' && descObj['nodeNums'] == true,
                            }"
                        ></i>
                        </div>
                    </span>
                </template>
                <template slot-scope="scope">
                    <el-tooltip 
                        :disabled="!(scope.row.nodeCount> 0)" 
                        placement="top" 
                        effect="dark">
                        <div slot="content">
                            <span class="completedDot"></span>{{$t('home.status.completed')+':'+scope.row.nodeNum[0]}}<br/>
                            <span class="inProgressDot"></span>{{$t('home.status.inProgress')+':'+scope.row.nodeNum[1]}}<br/>
                            <span class="notStartedDot"></span>{{$t('home.status.notStarted')+':'+scope.row.nodeNum[2] }}<br/>
                            <span class="totalDot"></span>{{$t('home.total')+':'+scope.row.nodeCount }}
                        </div>
                        <div
                            style="
                                height: 20px;
                                border-radius: 4px;
                                background-color: #a6a6a6;
                                display: flex;
                                align-items: center;
                            "
                            :style="'max-width: 480px;width:'+
                            (scope.row.nodeCount/maxNodeNum * 480) + 'px'
                            "
                            >
                            <div
                                :style="
                                'background-color:#2a82e4;border-radius: 4px;display: flex;align-items: center;width:'+
                                (((scope.row.nodeNum[0]+scope.row.nodeNum[1])/scope.row.nodeCount) * (scope.row.nodeCount/maxNodeNum * 480))+
                                    'px'
                            ">
                                    <div
                                        v-if="scope.row.nodeNum[0] > 0"
                                        :style="
                                        'color:rgb(0 167 11);height:20px;border-radius: 4px;display: flex;justify-content: center;align-items: center;background-color:#43cf7c;' +
                                        'width:' +
                                        ((scope.row.nodeNum[0]/scope.row.nodeCount) * (scope.row.nodeCount/maxNodeNum * 480))+
                                        'px'
                                        "
                                    >
                                    </div>
                                    <div
                                        v-if="scope.row.nodeNum[1] > 0"
                                        :style="
                                        'color:#2a82e4;height:20px;border-radius: 4px;display: flex;justify-content: center;align-items: center;background-color:#2a82e4;' +
                                        'width:' +
                                        ((scope.row.nodeNum[1]/scope.row.nodeCount) * (scope.row.nodeCount/maxNodeNum * 480)) +
                                        'px'
                                        "
                                    >
                                    </div>                            
                            </div>
                                <div
                                    v-if="scope.row.nodeNum[2] > 0"
                                    :style="
                                    'color:#a6a6a6;height:20px;display: flex;justify-content: center;align-items: center;' +
                                    'width:' +
                                    ((scope.row.nodeNum[2]/scope.row.nodeCount) * (scope.row.nodeCount/maxNodeNum * 480))+
                                    'px'
                                    "
                                >
                                </div>                        
                        </div>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>
        <div class="foot">
            <div
                style="
                display: flex;
                justify-content: flex-end;
                padding-right: 20px;
                height: 50px;
                align-items: center;
                "
            >
                <el-pagination
                :current-page.sync="currentPage"
                background
                :page-size="size"
                :pager-count="5"
                layout="total, prev, pager, next, jumper"
                :total="total_elements"
                @current-change="handleCurrentChange"
                >
                </el-pagination>
            </div>
        </div>
    </el-card>
</template>

<script>
import {get_projectNums} from "@/network/workstation/index.js";
import vmson from "@/utils/vmson";
export default {
    data() {
        return {
            card_list:[],
            currentPage: 1,
            size:8,
            total_elements: 0,
            dataLoading: false,
            tableLoaded:false,
            multipleSelection:[],
            select:[],
            timer:null,
            sortType:'fileNums',
            desc:true,
            descObj:{
                'fileNums':true,
                'nodeNums':true
            },
            sortOrders:['ascending', 'descending'],
            maxFileNum:0,
            maxNodeNum:0
        }
    },
    created(){
        this.getProjectNums();
        this.getMaxNodeNum();
        this.tableLoaded = true;
    },
    methods: {
        changeCellStyle({ row, column, rowIndex, columnIndex }) {
            return "cursor:pointer;";
        },
        handleCurrentChange(){
            this.getProjectNums();
        },
        rowClicked(){},
        async getProjectNums(){
            this.dataLoading = true
            let query = {
                page:this.currentPage-1,
                size:this.size,
                desc:this.desc,
                sortType:this.sortType
            }
            await get_projectNums(query).then(res=>{
                this.card_list = []
                this.maxFileNum = Math.max(res.content[0].fileNum,this.maxFileNum)
                for(let item of res.content){
                    let obj = {
                        projectId:'',
                        projectName:'',
                        num:0,
                        fileNum:[0,0,0],
                        nodeNum:[0,0,0],
                        fileCount:0,
                        nodeCount:0
                    }
                    obj.projectId = item.projectId
                    obj.projectName = item.projectName;
                    obj.num = item.memCount
                    obj.fileCount = item.fileNum
                    obj.nodeCount = item.nodeNum
                    // file
                    let flag = {'IN_PROGRESS':0,'TODO':0,'DONE':0};
                    for(let fs of item.fileCategoryDistributionList){
                        if(fs.statusCategory == 'IN_PROGRESS'){
                            obj.fileNum[1] = (fs.count || 0);
                            flag['IN_PROGRESS'] = fs.count;
                        }
                        else if(fs.statusCategory == 'TODO'){
                            obj.fileNum[2] = (fs.count || 0);
                            flag['TODO'] = fs.count;
                        }
                        else if(fs.statusCategory == 'DONE'){
                            obj.fileNum[0] = (fs.count || 0);
                            flag['DONE'] = fs.count;
                        }
                    }
                    if(flag['IN_PROGRESS'] == 0){
                        obj.fileNum[1] = 0;
                    }
                    if(flag['TODO'] == 0){
                        obj.fileNum[2] = 0;
                    }
                    if(flag['DONE'] == 0){
                        obj.fileNum[0] = 0;
                    }
                    // node
                    let flag2 = {'IN_PROGRESS':0,'TODO':0,'DONE':0};
                    for(let ns of item.nodeCategoryDistributionList){
                        if(ns.statusCategory == 'IN_PROGRESS'){
                            obj.nodeNum[1] = (ns.count || 0);
                            flag2['IN_PROGRESS'] = ns.count;
                        }
                        else if(ns.statusCategory == 'TODO'){
                            obj.nodeNum[2] = (ns.count || 0);
                            flag2['TODO'] = ns.count;
                        }
                        else if(ns.statusCategory == 'DONE'){
                            obj.nodeNum[0] = (ns.count || 0);
                            flag2['DONE'] = ns.count;
                        }
                    }
                    if(flag2['IN_PROGRESS'] == 0){
                        obj.nodeNum[1] = 0;
                    }
                    if(flag2['TODO'] == 0){
                        obj.nodeNum[2] = 0;
                    }
                    if(flag2['DONE'] == 0){
                        obj.nodeNum[0] = 0;
                    }
                    this.card_list.push(obj)  
                }
                this.dataLoading = false 
                this.total_elements = res.totalElements
            })
            this.setCheckedRows();
        },
        goProject(projectId){
            if(projectId){
                const { href } = this.$router.resolve({path:`/${projectId}/home/my_file`})
                window.open(href, "_blank");
            }
        },
        getRowKeys(row){
		    return row.projectId
		 },
        debounce(delay) {
            let that = this;
            return function () {
                if (that.timeout !== null) {
                    clearTimeout(that.timer);
                    that.timer = null;
                }
                that.timer = setTimeout(() => {
                    vmson.$emit('getDataByPid',that.select);
                }, delay);
            };
        },
        handleSelectionChange(val) {
            let tempSelect = val.map( (item) => item.projectId);
            this.select[this.currentPage-1] = tempSelect
            this.multipleSelection = val;
            let debouncedHandler = this.debounce(100);
            debouncedHandler();
        },
        setCheckedRows(){
			let selectItem = []	
            if(this.select[this.currentPage-1]){
                this.card_list.forEach(item => {
                    this.select[this.currentPage-1].forEach(id => {
                        if(item.projectId === id){
                            selectItem.push(item)
                        }
                    })
                })
                if(selectItem.length){
                    for(let row of selectItem){
                        this.$refs.multipleTable.toggleRowSelection(row,true);	
                    }
                }
            }
			
		},
        async handleSort(type){
            // 点击排序清空选择项
            this.$refs.multipleTable.clearSelection()
            this.select = []
            let debouncedHandler = this.debounce(100);
            debouncedHandler();
            this.descObj[type] = !this.descObj[type]
            this.desc = this.descObj[type]
            this.sortType = type
            await this.getProjectNums();
        },
        async getMaxNodeNum(){
            let query = {
                page:this.currentPage-1,
                size:this.size,
                desc:this.desc,
                sortType:'nodeNums'
            }
            await get_projectNums(query).then(res=>{
                this.maxNodeNum = res.content[0].nodeNum
            })
        }
    },
}
</script>

<style lang="scss" scoped>
.detailBox{
    height: 320px;
    padding: 10px;
}
.colColor{
    font-weight: 700 !important;
    letter-spacing: 0px !important;
    line-height: 20px !important;
    color: rgba(5, 81, 173, 1) !important;
}
::v-deep .el-card__body{
    display:flex;
    flex-direction: column;
    height: 305px;
}
.completedDot{
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #43cf7c;
    margin-right: 5px;
}
.inProgressDot{
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #2a82e4;
    margin-right: 5px;
}
.notStartedDot{
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #a6a6a6;
    margin-right: 5px;
}
.totalDot{
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 5px;
}
.sortable-column-header {
  display: flex;
  align-items: center;
  // cursor: pointer;
}
.caret-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.el-icon-caret-top {
  transform: translate(0, 3px);
}

.el-icon-caret-bottom {
  transform: translate(0, -3px);
}

.el-icon-caret-colored {
  color: #068bff;
}
</style>