<template>
    <el-card id="node-assignee">
        <h3 style="text-align: start;margin: 0;font-size: 18px;">{{ $t('home.nodeResponsiblePerson') }}</h3>
        <div
        id="echarts_main_wrap"
        ref="echarts_main_wrap"
        class="echarts_main_wrap"
        >
            <div class="pie1">
                <div id="pie1" ref="pie1"></div>
            </div>
        </div>
    </el-card>
</template>
    
    
<script>
import {get_nodeAssigneeByTenantId} from "@/network/workstation/index.js";
import vmson from "@/utils/vmson";
export default{
    data() {
        return {
            optionsList:[ ],
            myChart_pie: '',
        }
    },
    methods: {
        debounce(fn, delay) {
            let timer = null;
            return function () {
                if (timer) {
                clearTimeout(timer);
                timer = null;
                }
                timer = setTimeout(() => {
                fn.resize();
                }, delay);
            };
        },
        async init_charts(projectIds=[]) {
            await this.getNodeAssigneeByTenantId(projectIds);
            this.myChart_pie = this.$echarts.init(document.getElementById("pie1"));
            this.resize_handler = this.debounce(this.myChart_pie, 500);
            window.addEventListener("resize", this.resize_handler);
            let option0 = {
                tooltip: {
                formatter: "{b} <br/> <b>{c}</b> <br/><b>{d}%</b>",
                },
                legend: {
                    icon: "circle",
                    type: "scroll",
                    orient: "vertical",
                    align: "left",
                    right: "5%",
                    top: "10%",
                    width: "20%",
                    height: "80%",
                    textStyle: {
                        width: 150,
                        fontSize: 14,
                        overflow: "breakAll",
                        rich: {
                        title: {
                            align: "left",
                        },
                        value: {
                            align: "right",
                        },
                        },
                    },
                    tooltip: {
                        show: true,
                    },
                },
                tooltip: {
                    show: true,
                },
                series: [
                {
                    left: -95,
                    type: "pie",
                    minAngle: 5,
                    data: this.optionsList,
                    label: {
                    show: false,
                    },
                    itemStyle: {
                    borderColor: "#fff",
                    borderWidth: 2,
                    },
                    label: {
                        normal: {
                                show: true,
                                position: 'outer',
                                formatter: '{value|{d}%}',
                                rich: {
                                    value: {
                                        fontSize: 14,
                                        color:'black',
                                    },
                                },
                            }
                    },
                },
                ],
                
                
            };
            this.myChart_pie.setOption(option0);
        },
        async getNodeAssigneeByTenantId(projectIds){
            await get_nodeAssigneeByTenantId(projectIds).then(res=>{
                this.optionsList = []
                let temp = {...this.$store.state.store_tenant.user};
                let listObj = {}
                for(let item of res){
                    if(item.assignee && temp[item.assignee] && temp[item.assignee].deleted==0 && temp[item.assignee].nickname){
                        if(listObj[temp[item.assignee].nickname]){
                            listObj[temp[item.assignee].nickname]+=item.count;
                        }else{
                            listObj[temp[item.assignee].nickname]=item.count;
                        }
                    }
                }
                for(let user of Object.keys(listObj)){
                    this.optionsList.push({['name']:user,['value']:listObj[user]})
                }

                // 倒序
                this.optionsList.sort((a,b)=> b.value - a.value)
            })
        }
    },
    async mounted(){
        await this.init_charts();
        vmson.$on('getDataByPid',async (id)=>{
            await this.getNodeAssigneeByTenantId(id.flat(Infinity));
            this.myChart_pie.setOption({
                series: [{
                data: this.optionsList
                }]
            });
        })
    },
    beforeDestroy() {
        /* 页面组件销毁的时候，别忘了移除绑定的监听resize事件，否则的话，多渲染几次
        容易导致内存泄漏和额外CPU或GPU占用哦*/
        window.removeEventListener("resize", this.resize_handler);
    },
}
</script>
    
    
<style lang="scss" scoped>
#node-assignee{
    height: 250px;
    padding: 10px;
}
.echarts_main_wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: hidden auto;
  & > div {
    margin-top: 50px;
    overflow: hidden;
    height: 200px;
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    & > div {
      width: 100%;
      height: 195px;
    }
  }
  .pie1 {
    overflow: hidden;
    width: 100%;
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    margin-top: 0px;
  }  
}  
</style>

