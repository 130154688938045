<template>
  <div style="background-color: rgb(240, 242, 245); height: 100vh">
    <home-topbar
      class="topbar"
      :is-home="false"
      :is-personal="false"
      :is-notification="false"
      :is-worksapce="true"
      @dialoghover="false"
    ></home-topbar>

    <el-scrollbar style="height: 88vh; width: 100%">
      <div class="contain">
        <h2 style="text-align: start">
          <i
            class="iconfont icon-a-file-chart1"
            style="color: rgba(7, 83, 174, 1); margin-right: 3px"
          ></i
          >{{ $t("home.projectDataSummary") }}
        </h2>
        <projectDetail></projectDetail>
        <div class="row-box">
          <node-assignee></node-assignee>
          <workTimeStatistics></workTimeStatistics>
        </div>
        <assignee-node-completion></assignee-node-completion>
        <!-- <mainMapData></mainMapData> -->
        <share v-if="role == 'SYSTEM_MANAGER'"></share>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import HomeTopbar from "@/components/homeTopbar";
import nodeAssignee from "./components/nodeAssignee.vue";
import workTimeStatistics from "./components/workTimeStatistics.vue";
import projectDetail from "./components/projectDetail.vue";
import assigneeNodeCompletion from "./components/assigneeNodeCompletion.vue";
import share from "./components/share.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    nodeAssignee,
    HomeTopbar,
    workTimeStatistics,
    projectDetail,
    assigneeNodeCompletion,
    share,
  },
  data() {
    return {
      statusList: [],
      role: "",
    };
  },
  computed: {
    ...mapGetters(["roles"]),
  },
  created() {
    this.role = this.roles[0].name;
  },
  methods: {},
};
</script>

<style scoped></style>

<style lang="scss" scoped>
.topbar {
  height: 60px;
  background-color: rgba(48, 100, 143, 1);
  width: 100vw;
  padding: 0;
}
::v-deep .el-scrollbar__wrap {
  overflow: hidden auto;
}
.contain {
  margin: 0 auto;
  width: 90vw;
  .row-box {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 49% 49%;
    grid-column-gap: 1%;
    grid-row-gap: 1%;
    margin: 25px 0 25px 0;
  }
}
::v-deep .el-card__body {
  padding: 0;
}
::v-deep .el-table::before {
  content: none;
}
::v-deep .el-table__fixed::before {
  content: none;
}
</style>
