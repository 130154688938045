<template>
    <el-card id="workTime">
        <h3 style="text-align: start;margin: 0;font-size: 18px;">{{ $t('home.nodeCompletionStatus') }}</h3>
        <div>
            <el-table 
                :data="tableData"
                v-if="tableLoaded" 
                height="190"
                :cell-style="{'line-height':'18px','padding':'6px 0','height':'10px'}" 
                :header-cell-style="{'line-height':'18px','padding':'6px 0','height':'10px'}" 
                style="width: 100%;font-size: 14px;">
                <!-- 左上角空白列 -->
                <el-table-column fixed="left" label=" " width="120">
                    <template slot-scope="scope">
                        <div style="cursor: pointer;display: flex;flex-direction: row;align-items: center;" @click="sortByStatus( scope )">
                            <span style="color: rgba(5, 81, 173, 1);font-weight: 700;">{{ tableType[scope.$index].type }}</span>
                            <div class="sortBox">
                                <i class="iconfont icon-sheng" style="font-size: 6px;height: 8px;" :style="'color:'+ (eachRowCurrentSort[scope.$index] == 'ascending'?'#068bff;':' ')"></i>
                                <i class="iconfont icon-jiang" style="font-size: 6px;height: 8px;" :style="'color:'+ (eachRowCurrentSort[scope.$index] == 'descending'?'#068bff;':' ')"></i>
                            </div>
                        </div>
                    </template>
                </el-table-column>

                <!-- 人名表头 -->
                <el-table-column v-for="name in pageData" :key="name" align="center" :label="name" min-width="100">
                    <template slot="header" slot-scope="scope">
                        <el-tooltip
                            effect="dark"
                            :content="name"
                            :disabled="!isShowToolTip"
                            placement="top"
                        >  
                            <div @mouseenter="(event) => ifShowToolTip(event)">{{ name }}</div>

                        </el-tooltip>
                    </template>
                    <template slot-scope="scope">
                        <span>{{ scope.row[name] }}</span>
                    </template>
                </el-table-column>
            </el-table>   
            <i class="iconfont icon-shengxu"></i>       
        </div>
        <div class="foot">
            <div
                style="
                display: flex;
                justify-content: flex-end;
                padding-right: 20px;
                height: 50px;
                align-items: center;
                "
            >
                <el-pagination
                :current-page.sync="currentPage"
                background
                :page-size="size"
                :pager-count="5"
                layout="total, prev, pager, next, jumper"
                :total="total_elements"
                @current-change="handleCurrentChange"
                >
                </el-pagination>
            </div>
        </div>
    </el-card>   
</template>
    
    
<script>
import {get_assigneeStatusByTenantId} from "@/network/workstation/index.js";
import { textRange } from "@/utils/textRange";
import vmson from "@/utils/vmson";
export default{
    data() {
        return {
            people: [], // 人名列表
            dataArr:[], // 后端传过来的未经出来的数据
            tableData: [
                
            ],
            tableType: [
                { type:  this.$t('home.status.notStarted') },
                { type:  this.$t('home.status.inProgress') },
                { type:  this.$t('home.status.completed') },
                { type:  this.$t('home.total') },
            ],
            notStartedArr:[],
            inProgressArr:[],
            completedArr:[],
            totalArr:[],
            currentPage: 1,
            size:8,
            total_elements: 0,
            tableLoaded:false,
            isShowToolTip:true,
            eachRowCurrentSort:[
            '','','','ascending'
            ],
        }
    },
    computed: {
    // 计算当前页面的表格数据
        pageData() {
            const start = (this.currentPage - 1) * this.size;
            const end = this.currentPage * this.size;
            return this.people.slice(start, end);
        }
    },
    async mounted() {
        await this.getAssigneeStatusByTenantId()
        this.buildTableData();
        this.sortByStatus({'$index':3})
        vmson.$on('getDataByPid',async (id)=>{
            this.tableLoaded = false;
            await this.getAssigneeStatusByTenantId(id.flat(Infinity));
            this.buildTableData()
            this.eachRowCurrentSort[3] = 'ascending';
            this.sortByStatus({'$index':3})
            this.tableLoaded = true;
        })
    },
    methods:{
        handleCurrentChange() {
            // const params = {
            //     action: "assign",
            //     pageNumber: this.currentPage - 1,
            //     pageSize: this.size,
            // };
            // // get_notifications_list(params).then((res) => {
            // //   this.total_elements = res.total;
            // //   this.card_list = res.notificationDetailDtoList;
            // // });
            // this.get_file();
        },
        buildTableData(){
            // 未开始
            this.tableData[0] = this.people.reduce((obj, person, index) => {
            obj[person] = this.notStartedArr[index];
            return obj;
            }, {});

            // 进行中
            this.tableData[1] = this.people.reduce((obj, person, index) => {
            obj[person] = this.inProgressArr[index];
            return obj;
            }, {});

            // 已完成
            this.tableData[2] = this.people.reduce((obj, person, index) => {
            obj[person] = this.completedArr[index];
            return obj;
            }, {});
            
            // 总计
            this.tableData[3] = this.people.reduce((obj, person, index) => {
            obj[person] = this.totalArr[index];
            return obj;
            }, {});
        },
        async getAssigneeStatusByTenantId(projects=[]){
            await get_assigneeStatusByTenantId(projects).then(res=>{
                this.people= [];
                this.notStartedArr= [];
                this.inProgressArr= [];
                this.completedArr= [];
                this.totalArr= [];
                let temp = {...this.$store.state.store_project.status};
                let temp2 = {...this.$store.state.store_tenant.user};
                this.dataArr = res
                for(let item of res){
                    if(item.assignee && temp2[item.assignee] && temp2[item.assignee].nickname && temp2[item.assignee].deleted==0){
                        this.people.push(temp2[item.assignee].nickname)
                        if(item.categoryDtoList.length>0){
                            let flag = {'IN_PROGRESS':0,'TODO':0,'DONE':0};
                            for(let s of item.categoryDtoList){
                                if(s.statusCategory == 'IN_PROGRESS'){
                                    this.inProgressArr.push(s.count || 0);
                                    flag['IN_PROGRESS'] = s.count;
                                }
                                else if(s.statusCategory == 'TODO'){
                                    this.notStartedArr.push(s.count || 0);
                                    flag['TODO'] = s.count;
                                }
                                else if(s.statusCategory == 'DONE'){
                                    this.completedArr.push(s.count || 0);
                                    flag['DONE'] = s.count;
                                }
                            }
                            if(flag['IN_PROGRESS'] == 0){
                                this.inProgressArr.push(0);
                            }
                            if(flag['TODO'] == 0){
                                this.notStartedArr.push(0);
                            }
                            if(flag['DONE'] == 0){
                                this.completedArr.push(0);
                            }
                            this.totalArr.push(flag['IN_PROGRESS']+flag['TODO']+flag['DONE'])
                        }
                        
                    }
                }
                this.total_elements = this.people.length
            })
        },
        ifShowToolTip(event) {
            this.isShowToolTip = textRange(event.target);
        },
        clearSort(nowIndex){
            this.eachRowCurrentSort.forEach((item,index)=>{
                if(nowIndex != index){
                    this.eachRowCurrentSort[index] = ''
                }
            })
        },
        sortByStatus(sortRow){
            this.tableLoaded = false;
            let index = sortRow.$index
            this.clearSort(index)
            this.eachRowCurrentSort[index] = this.eachRowCurrentSort[index] === 'ascending' ? 'descending' : 'ascending';
            let usersArray = Object.keys(this.tableData[index]).map(key => {
                return { name: key, value: this.tableData[index][key] };
            });
            if (this.eachRowCurrentSort[index] === 'ascending') {
                usersArray = usersArray.sort((a,b)=>a.value-b.value)
            } else {
                usersArray = usersArray.sort((a,b)=>b.value-a.value)
            }
            let newPeople = usersArray.map(item=>{
                return item.name
            })
            this.people = newPeople
            // let test = []
            // this.tableData.forEach((row,index) => {
            //     let tempObj0 = {};
            //     newPeople.forEach(name => {
            //         tempObj0[name] = row[name];
            //     });
            //     test.push({...tempObj0})
            //     this.tableData[index] = {...tempObj0}
            // })
            // console.log(test);
            // this.tableData = []
            // this.tableData = test
            // console.log(this.tableData);
            this.$nextTick(()=>{
                this.tableLoaded = true;
            })
            
            
            
        }
    }
}   
</script>
    
    
<style scoped>
#workTime{
    height: 280px;
    padding: 10px;
    margin-bottom: 25px;
}
::v-deep .el-table__header .cell .el-tooltip{
    white-space:nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.sortBox{
    transform: translateY(-8px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 3px;
}

</style>