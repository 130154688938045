<template>
    <el-card id="workTime">
        <h3 style="text-align: start;margin: 0;font-size: 18px;">{{ $t('home.workHoursStatistics') }}</h3>
        <div>
            <el-table 
                :data="tableData" 
                v-if="tableLoaded"
                height="163px"
                :cell-style="{'line-height':'18px',}" 
                :header-cell-style="{'line-height':'18px'}" 
                style="width: 98%;font-size: 14px;">
                <!-- 左上角空白列 -->
                <el-table-column fixed="left" label=" " :show-overflow-tooltip="true" width="150" prop="type">
                </el-table-column>

                <!-- 人名表头 -->
                <el-table-column v-for="name in pageData" :key="name" :show-overflow-tooltip="true" min-width="120">
                    <template slot="header" slot-scope="scope">
                        <el-tooltip
                            effect="dark"
                            :content="name"
                            :disabled="!isShowToolTip"
                            placement="top"
                        >  
                            <div @mouseenter="(event) => ifShowToolTip(event)">{{ name }}</div>

                        </el-tooltip>
                    </template>
                    <template slot-scope="scope">
                        <span>{{ scope.row[name] }}</span>
                    </template>
                </el-table-column>
            </el-table>          
        </div>
        <div class="foot">
            <div
                style="
                display: flex;
                justify-content: flex-end;
                padding-right: 20px;
                height: 50px;
                align-items: center;
                "
            >
                <el-pagination
                :current-page.sync="currentPage"
                background
                :page-size="size"
                :pager-count="5"
                layout="total, prev, pager, next, jumper"
                :total="total_elements"
                @current-change="handleCurrentChange"
                >
                </el-pagination>
            </div>
        </div>
    </el-card>   
</template>
    
    
<script>
import {get_manHourByTenantId} from "@/network/workstation/index.js";
import vmson from "@/utils/vmson";
import { textRange } from "@/utils/textRange";
export default{
    data() {
        return {
            people: [], // 人名列表
            tableData: [
                { type: this.$t('home.plannedWorkHours') },
                { type: this.$t('home.actualRegisteredWorkHours') }
            ],
            planHours:[],
            actualHours:[],
            currentPage: 1,
            size:6,
            total_elements: 0,
            tableLoaded:false,
            isShowToolTip:true
        }
    },
    computed: {
    // 计算当前页面的表格数据
        pageData() {
            const start = (this.currentPage - 1) * this.size;
            const end = this.currentPage * this.size;
            return this.people.slice(start, end);
        }
    },
    async created() {
        await this.getManHourByTenantId();
        // 假设这里的工时数据是从后端获取的，这里只是示例数据
        const planHours = this.planHours; // 计划工时数据
        const actualHours = this.actualHours; // 实际登记工时数据

        this.tableData[0] = this.people.reduce((obj, person, index) => {
        obj[person] = planHours[index];
        return obj;
        }, { type: this.$t('home.plannedWorkHours') });

        this.tableData[1] = this.people.reduce((obj, person, index) => {
        obj[person] = actualHours[index];
        return obj;
        }, { type: this.$t('home.actualRegisteredWorkHours') });
        this.tableLoaded = true;
    },
    mounted(){
        vmson.$on('getDataByPid',async (id)=>{
            this.tableLoaded = false;
            await this.getManHourByTenantId(id.flat(Infinity));
            const planHours = this.planHours; // 计划工时数据
            const actualHours = this.actualHours; // 实际登记工时数据

            this.tableData[0] = this.people.reduce((obj, person, index) => {
            obj[person] = planHours[index];
            return obj;
            }, { type: this.$t('home.plannedWorkHours') });

            this.tableData[1] = this.people.reduce((obj, person, index) => {
            obj[person] = actualHours[index];
            return obj;
            }, { type: this.$t('home.actualRegisteredWorkHours') });
            this.tableLoaded = true;
            this.tableLoaded = true;
        })
    },
    methods:{
        handleCurrentChange() {
            // const params = {
            //     action: "assign",
            //     pageNumber: this.currentPage - 1,
            //     pageSize: this.size,
            // };
            // // get_notifications_list(params).then((res) => {
            // //   this.total_elements = res.total;
            // //   this.card_list = res.notificationDetailDtoList;
            // // });
            // this.get_file();
            this.tableLoaded = false
            this.$nextTick(()=>{
                this.tableLoaded = true 
            })
        },
        async getManHourByTenantId(projects=[]){
            await get_manHourByTenantId(projects).then(res=>{
                this.people= []
                this.planHours=[];
                this.actualHours=[];
                let temp = {...this.$store.state.store_tenant.user};
                for(let item of res){
                    if(item.assignee && temp[item.assignee] && temp[item.assignee].deleted==0){
                        this.people.push(temp[item.assignee].nickname);
                        this.planHours.push((parseFloat(item.planManHour/1000)).toFixed(2));
                        this.actualHours.push((parseFloat(item.manHour/1000)).toFixed(2))
                    }
                }
                this.total_elements = this.people.length
            })
        },
        ifShowToolTip(event) {
            this.isShowToolTip = textRange(event.target);
        },
    },
}   
</script>
    
    
<style scoped>
#workTime{
    height: 250px;
    padding: 10px;
}
::v-deep .el-table__header .cell .el-tooltip{
    white-space:nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>