<template>
  <el-card id="workTime">
    <h3 style="text-align: start; margin: 0; font-size: 18px">
      {{ $t("share.summer") }}
    </h3>
    <div>
      <el-table
        :data="tableData"
        height="190"
        :cell-style="{
          'line-height': '18px',
          padding: '6px 0',
          height: '10px',
        }"
        :header-cell-style="{
          'line-height': '18px',
          padding: '6px 0',
          height: '10px',
        }"
        style="width: 100%; font-size: 14px"
      >
        <el-table-column
          prop="fileKey"
          :label="'Key'"
          min-width="50"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span class="colKeyColor" @click="goFile(scope.row.fileKey,scope.row.projectId)">{{ scope.row.fileKey }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="fileName"
          :label="$t('share.fname')"
          min-width="250"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span class="colColor" @click="goFile(scope.row.fileKey,scope.row.projectId)">{{ scope.row.fileName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="updatedBy"
          :label="$t('share.people')"
          min-width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ all_users[scope.row.createdBy].nickname }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="updatedAt"
          :label="$t('share.date')"
          min-width="200"
          show-overflow-tooltip
        ></el-table-column>

        <el-table-column :label="$t('share.nosha')" width="125" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              circle
              style="color: rgba(5, 81, 173, 1)"
              @click="switch_change(scope.row)"
            >取消</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="foot">
      <div
        style="
          display: flex;
          justify-content: flex-end;
          padding-right: 20px;
          height: 50px;
          align-items: center;
        "
      >
        <el-pagination
          :current-page.sync="currentPage"
          background
          :page-size="size"
          :pager-count="5"
          layout="total, prev, pager, next, jumper"
          :total="total_elements"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
  </el-card>
</template>

<script>
import { get_tenant_share, change_file_share } from "@/network/share/index.js";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      people: [], // 人名列表
      dataArr: [], // 后端传过来的未经出来的数据
      tableData: [],
      notStartedArr: [],
      inProgressArr: [],
      completedArr: [],
      totalArr: [],
      currentPage: 1,
      size: 5,
      total_elements: 0,
      tableLoaded: false,
      isShowToolTip: true,
      eachRowCurrentSort: ["", "", "", "ascending"],
    };
  },
  computed: {
    ...mapGetters({
      all_users: "user_list",
    }),
    // 计算当前页面的表格数据
    pageData() {
      const start = (this.currentPage - 1) * this.size;
      const end = this.currentPage * this.size;
      return this.people.slice(start, end);
    },
  },

  mounted() {
    this.handleCurrentChange();
  },
  methods: {
    switch_change(data) {
      change_file_share(data.projectId, data.fileKey, false).then(() => {
        if(this.tableData.length == 1 && this.currentPage>1){
          this.currentPage -= 1;
        }
        this.handleCurrentChange();
      });
    },
    handleCurrentChange() {
      const params = {
        page: this.currentPage - 1,
        size: this.size,
      };
      get_tenant_share(params).then((res) => {
        this.tableData = res.content;
        this.total_elements = res.totalElements;
      });
    },
    goFile(fileKey,projectId){
      const { href } = this.$router.resolve({
        path: `/${projectId}/myMind/${fileKey}`,
      });
      window.open(href, "_blank");
    },
  },
};
</script>

<style scoped>
#workTime {
  height: 280px;
  padding: 10px;
  margin-bottom: 25px;
}
::v-deep .el-table__header .cell .el-tooltip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sortBox {
  transform: translateY(-8px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 3px;
}
.colColor{
    cursor: pointer;
    font-weight: 700 !important;
    letter-spacing: 0px !important;
    line-height: 20px !important;
    color: rgba(5, 81, 173, 1) !important;
}
.colKeyColor{
  cursor: pointer;
  color: rgb(255, 195, 0);
  font-weight: 700;
}
</style>
